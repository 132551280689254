@use "./assets/styles/_colors" as colors;

@use "ngx-toastr/toastr" as *;

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Make images easier to work with */
img,
picture,
svg,
video {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
  font-style: italic;
  shape-margin: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

p {
  text-wrap: pretty;
  max-width: 62ch;
}

/* Remove default margin */
* {
  margin-inline: 0;
  margin-block: 0;
  padding-inline: 0;
  padding-block: 0;
  font-size: calc(15px + 0.390625vw) !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font: inherit;
}

html {
  hanging-punctuation: first last;
}

body {
  text-rendering: optimizeSpeed;
  min-height: 100svh;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :has(:target) {
    scroll-behavior: smooth;
    scroll-padding-top: 3rem;
  }
}

p:not([class]) {
  max-width: 32ch;
}

p[data-width="wide"] {
  max-width: 42ch;
}

//* Custom */
.ng-tooltip-default {
  position: absolute;
  max-width: 150px;
  font-size: 16px !important;
  text-align: center;
  color: colors.$white;
  padding: 3px 8px;
  background: colors.$primary;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip-default:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-default-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: colors.$primary transparent transparent transparent;
}
.ng-tooltip-default-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent colors.$primary transparent;
}
.ng-tooltip-default-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent colors.$primary;
}
.ng-tooltip-default-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent colors.$primary transparent transparent;
}
.ng-tooltip-default-show {
  opacity: 1;
}

.ng-tooltip-delete {
  position: absolute;
  max-width: 150px;
  font-size: 16px !important;
  text-align: center;
  color: colors.$white;
  padding: 3px 8px;
  background: colors.$error;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip-delete:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-delete-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: colors.$error transparent transparent transparent;
}
.ng-tooltip-delete-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent colors.$error transparent;
}
.ng-tooltip-delete-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent colors.$error;
}
.ng-tooltip-delete-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent colors.$error transparent transparent;
}
.ng-tooltip-delete-show {
  opacity: 1;
}
